body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

#root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
